body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
    outline: none !important;
    box-sizing: border-box;
}

body {
    position: relative;
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
    /*min-width: 760px;*/
}

#root {
    font-family: "Roboto", "Helvetica", "Arial", "Microsoft Yahei", "\5FAE\8F6F\96C5\9ED1", sans-serif;
    color: rgba(0, 0, 0, .77)
}

.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.section-title {
    font-size: 26px;
    color: #1a0e64;
    text-align: center;
    letter-spacing: .5em;
    margin-left: .5em
}

.section-subtitle {
    font-size: 21px;
    color: #19aae4;
    text-align: center;
    letter-spacing: .1em;
    margin-left: .1em
}

.section-subtitle2 {
    font-size: 16px;
    color: #8bc831;
}

.content {
    font-size: 14px;
    margin: 0;
    line-height: 1.6;
}

.caption {
    font-size: 12px;
    margin: 0;
}

.text-indent {
    text-indent: 2em;
}

.step {
    width: 30px;
    height: 30px;
    background-image: linear-gradient(to bottom left, #8bc831, #19aae4);
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    display: block;
    font-style: normal;
}

.bg-top {

    background-image: linear-gradient(to left, #8bc831, #19aae4);
    color: white;
    margin-bottom: -20px;
    text-align: center;
    padding-bottom: 20px;
}

.bg-bottom {
    background-image: linear-gradient(to left, #8bc831, #19aae4);
    color: white;
    margin-top: -20px;
    text-align: center;
    padding-top: 20px;

}

.swiper-container {
    width: 100%;
    padding-top: 45px;
    padding-bottom: 5px;
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    top: 0;
    bottom: unset;
}

.swiper-pagination-bullet-active {
    background: gray;
}





